import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class CanAuthenticationGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService
  ) {
    super(router, keycloakAngular);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloakAngular.login({
        redirectUri: window.location.origin + state.url
      });
    }

    console.log('isAccessAllowed', this.authenticated);
    if (!this.authenticated) {
      return false;
    }

    const userRoles = this.keycloakAngular.getUserRoles(true);
    console.log('userRoles', userRoles);
    const requiredRoles: string[] = route.data.roles;
    console.log('requiredRoles: ', requiredRoles);
    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    } else {
      if (!this.roles || this.roles.length === 0) {
        return false;
      }
      return requiredRoles.every(role => this.roles.indexOf(role) > -1);
    }
  }
}
